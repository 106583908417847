import styled from 'styled-components'
import icon from 'src/images/belgium-postal-procuration.svg'
import { Colors } from 'src/components/UIKit'
import { useTranslation } from 'react-i18next'
import cloudDownload from 'src/images/download/download-cloud-blue.svg'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'

interface BelgiumPPActivationProps {
  postalProcuration?: string
  confirmPostalProcuration: any
}

const BelgiumPPActivation = ({
  postalProcuration,
  confirmPostalProcuration
}: BelgiumPPActivationProps) => {
  const { t } = useTranslation()
  return (
    <DownloadBelgiumWrapper>
      <div className='flex justify-between'>
        <div className='flex flex-col gap-2'>
          <div className='text-neutral-1 text-4 font-semibold'>
            {t('profile:belgium_postal_procuration.title')}
          </div>
          <div className='flex items-center gap-2'>
            <DownloadNumber>1</DownloadNumber>

            <div
              className='flex-1 text-5 [&>a]:text-interaction [&>a]:underline [&>a]:hover:no-underline [&>span]:font-semibold'
              dangerouslySetInnerHTML={{
                __html: t('profile:belgium_postal_procuration.firstStep')
              }}
            />
          </div>

          <div className='flex items-center gap-2'>
            <DownloadNumber>2</DownloadNumber>
            <div
              className='flex-1 text-5 [&>span]:font-semibold'
              dangerouslySetInnerHTML={{
                __html: t('profile:belgium_postal_procuration.secondStep')
              }}
            />
          </div>
        </div>
        <img
          src={icon}
          alt='letter'
          className='w-[130px] h-[180px] mobile:hidden'
        />
      </div>

      <div className='text-neutral-1 text-5'>
        <p>{t('profile:belgium_postal_procuration.serviceActivation')}</p>

        <p
          className='[&>a]:text-interaction [&>a]:underline [&>a]:hover:no-underline'
          dangerouslySetInnerHTML={{
            __html: t('profile:belgium_postal_procuration.blogPost')
          }}
        />
      </div>
      <div className='flex items-center gap-3 mobile:flex-col mobile:!items-start mobile:gap-2'>
        <Button
          type='a'
          buttonStyle
          bgColor='white'
          href={postalProcuration}
          target='_blank'
          width='fit-content'
          className='mobile:order-2'
        >
          {t('common:download')}
          <img
            className='ml-1'
            src={cloudDownload}
            alt='icone cloud download'
          />
        </Button>
        <Button
          bgColor='blue'
          onClick={confirmPostalProcuration}
          width='fit-content'
        >
          {t('profile:belgium_postal_procuration.send')}
        </Button>
      </div>
    </DownloadBelgiumWrapper>
  )
}

export const DownloadBelgiumWrapper = styled.div`
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const DownloadNumber = styled.div`
  background-color: #e7f2ff;
  color: ${Colors.blue};
  font-weight: 700;
  font-size: 14px;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default BelgiumPPActivation
