import gql from 'graphql-tag'

export const isDemo = (): boolean => {
  const currentUrl = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`

  const demoUrl =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? process.env.REACT_APP_DEMO_LINK_FR
      : `https://demo-espace-client.${process.env.REACT_APP_ROOT_PATH_FR}`

  return currentUrl === demoUrl
}

export const toggleDemoModal = (cache: any, show: boolean) => {
  cache.writeData({
    data: {
      demoModal: {
        __typename: 'DemoModal',
        show
      }
    }
  })
}

export const DEMO_MODAL_QUERY = gql`
  {
    demoModal @client {
      show
    }
  }
`

export const avisVerifiesRef = (wrapperRef: any) => {
  const meta = document.createElement('meta')
  meta.className = 'netreviewsWidget'
  meta.id = 'netreviewsWidgetNum19111'
  /* eslint-disable max-len */
  meta.setAttribute(
    'data-jsurl',
    '//cl.avis-verifies.com/fr/cache/6/9/3/69351402-07f9-0c84-4dfe-14a086464303/widget4/widget03-19111_script.js'
  )
  /* eslint-enable max-len */

  const script = document.createElement('script')
  script.src = '//cl.avis-verifies.com/fr/widget4/widget03_FB3.min.js'

  if (wrapperRef) {
    wrapperRef.append(meta)
    wrapperRef.append(script)
  }
}
