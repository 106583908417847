import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import '@sedomicilier/material-sd/src/globals.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { unregister } from './registerServiceWorker'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import ErrorBoundary from './components/shared/Bugsnag'
import { CountryContextProvider } from './contexts/countryContext'
import PublicRouting from './PublicRouting'
import Session from './session'
import AntdConfig from '@/AntdConfig'

import 'moment/locale/fr'
import moment from 'moment'

moment.locale('fr')

const root = createRoot(document.getElementById('root') as HTMLElement)

const getParameterByName = (name, url = window.location.href): string => {
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) {
    return ''
  }
  if (!results[2]) {
    return ''
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

if (getParameterByName('token')) {
  Session.setSession(getParameterByName('token'))
  const returnPath =
    window.location.href.split('?')[0] +
    (getParameterByName('landing_path') !== ''
      ? getParameterByName('landing_path')
      : '')
  window.location.assign(returnPath)
} else {
  Session.initSession()
  root.render(
    <CountryContextProvider>
      <AntdConfig>
        <ErrorBoundary>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <PublicRouting />
            </BrowserRouter>
          </I18nextProvider>
        </ErrorBoundary>
      </AntdConfig>
    </CountryContextProvider>
  )
}

unregister()
