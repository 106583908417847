import styled from 'styled-components'
import document from 'src/images/documents/document-blue.svg'
import downloadICon from 'src/images/download/blue-download-icon.svg'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import colors from '@/components/UIKit/colors'
import { useTranslation } from 'react-i18next'
import constants from 'src/constants'
import { Input } from 'antd'
import { ContactContext } from 'src/contexts/contactContext'
import { useContext } from 'react'
import session from 'src/session'

interface LegalDocumentDownloadProps {
  title: string
  subtitle?: string
  cta?: string
  href?: string
  disabled?: boolean
  withoutButton?: boolean
  domiciliationAttestation?: boolean
}

const LegalDocumentDownload = ({
  title,
  subtitle,
  cta,
  href,
  disabled,
  withoutButton,
  domiciliationAttestation
}: LegalDocumentDownloadProps) => {
  const { t } = useTranslation()
  const showIcon = cta === t('common:download')
  const { contact } = useContext(ContactContext)

  return (
    <LegalDocumentDownloadWrapper>
      <LegalDocumentDownloadIcon src={document} alt='icone document bleu' />
      <LegalDocumentContent>
        <LegalDocumentDownloadText>
          <LegalDocumentDownloadTitle>{title}</LegalDocumentDownloadTitle>
          {subtitle && (
            <div
              className='font-normal text-neutral-500 [&>span]:font-semibold'
              dangerouslySetInnerHTML={{
                __html: subtitle
              }}
            />
          )}
        </LegalDocumentDownloadText>
        {domiciliationAttestation ? (
          <AttestationForm
            action={`https://${
              process.env[
                'REACT_ROOT_PATH_' + contact.countryConfig.countryCode
              ]
            }/espace-client/attestation-de-domiciliation`}
            target='_blank'
            method='POST'
            style={{ width: '100%' }}
          >
            <Input type='hidden' name='token' value={session.getSession()} />
            <LegalDocumentButton>
              <Button target='_blank' bgColor='white' disabled={disabled}>
                {showIcon && (
                  <ButtonIcon src={downloadICon} alt='icon téléchargement' />
                )}
                {cta}
              </Button>
            </LegalDocumentButton>
          </AttestationForm>
        ) : (
          !withoutButton && (
            <LegalDocumentButton>
              <Button
                type='a'
                href={href}
                target='_blank'
                buttonStyle={true}
                bgColor='white'
                disabled={disabled}
              >
                {showIcon && (
                  <ButtonIcon src={downloadICon} alt='icon téléchargement' />
                )}
                {cta}
              </Button>
            </LegalDocumentButton>
          )
        )}
      </LegalDocumentContent>
    </LegalDocumentDownloadWrapper>
  )
}
export const AttestationForm = styled.form`
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
  }
`

export const LegalDocumentDownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    & > div {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }
`
export const LegalDocumentDownloadIcon = styled.img`
  margin: 0;
  margin-right: 16px;
`
export const LegalDocumentContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`
export const LegalDocumentButton = styled.div`
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
    a {
      justify-content: center;
      img {
        margin-left: 0;
      }
    }
    button {
      justify-content: center;
      img {
        margin-left: 0;
      }
    }
  }
`
export const LegalDocumentDownloadText = styled.div`
  font-size: 16px;
  font-family: 'Inter';
  line-height: 150%;
`
export const LegalDocumentDownloadTitle = styled.div`
  color: ${colors.black};
  font-weight: 600;
`

export const LegalDocumentLink = styled.a`
  border: 1px solid ${colors.blue};
  border-radius: 8px;
`
export const ButtonIcon = styled.img`
  margin-right: 8px;
`

export default LegalDocumentDownload
