import { NavLink } from 'react-router-dom'
import Notification from '../Notifications'
import { Dispatch, ReactNode, SetStateAction } from 'react'

interface IProps {
  to: string
  authorized: boolean
  children?: React.ReactNode
  onClick?: () => void
  icon: ReactNode
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>
  label: string
  notification?: number
  target?: string
}

const MobileNavItem = (props: IProps) => {
  if (props.authorized) {
    return (
      <NavLink
        to={props.to}
        onClick={() => props.setShowMobileMenu(false)}
        className='flex p-3 hover:no-underline items-center justify-between gap-[10px] '
        target={props.target}
      >
        <Content {...props} />
      </NavLink>
    )
  }

  return (
    <span className='flex p-3 opacity-50 cursor-default'>
      <Content {...props} />
    </span>
  )
}

const Content = ({ notification, icon, label }: IProps) => {
  return (
    <div className='flex items-center gap-1'>
      {icon}
      <span className='flex-1'>{label}</span>
      {!!notification && <Notification number={notification} />}
    </div>
  )
}

export default MobileNavItem
