import { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { List, Wrapper } from './styles'
import { Alert } from 'antd'
import BelgiumPostalProcuration from './belgiumPostalProcuration/belgiumPostalProcuration'
import LegalDocumentDownload from './legalDocumentDownload'

const AttestationsList = () => {
  const { t } = useTranslation()
  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  const { postalProcurationFlow } = domiciliation
  const { countryConfig } = contact

  const postalSignatureLink =
    postalProcurationFlow?.status === 'to_sign' &&
    postalProcurationFlow.signatureLink

  return (
    <Wrapper>
      <List>
        {domiciliation.contractDelivered && (
          <>
            {domiciliation.cancelationDate === null &&
              domiciliation.hasSigned && (
                <LegalDocumentDownload
                  title={t('profile:documents.domiciliationAttestation.title')}
                  subtitle={t(
                    'profile:documents.domiciliationAttestation.subtitle'
                  )}
                  cta={t('common:download')}
                  domiciliationAttestation
                />
              )}
            {domiciliation.contractSignatureUrl ? (
              <LegalDocumentDownload
                title={t('profile:documents.domiciliationContract.title')}
                subtitle={t('profile:documents.domiciliationContract.subtitle')}
                cta={t('common:signYourContract')}
                href={domiciliation.contractSignatureUrl}
              />
            ) : domiciliation.contractSignatureUrl === null &&
              domiciliation.contract ? (
              <LegalDocumentDownload
                title={t('profile:documents.domiciliationContract.title')}
                subtitle={t('profile:documents.domiciliationContract.subtitle')}
                cta={t('common:download')}
                href={domiciliation.contract}
              />
            ) : (
              (domiciliation.contractSignatureUrl !== null ||
                !domiciliation.contract) && (
                <LegalDocumentDownload
                  title={t('profile:documents.domiciliationContract.title')}
                  subtitle={t(
                    'profile:documents.domiciliationContract.subtitle'
                  )}
                  cta={t('common:download')}
                  withoutButton
                />
              )
            )}
          </>
        )}

        {!domiciliation.contractDelivered && (
          <LegalDocumentDownload
            title={t('profile:documents.domiciliationContract.title')}
            subtitle={t('profile:documents.domiciliationContract.unavailable')}
            withoutButton
          />
        )}

        {postalProcurationFlow?.link &&
          (countryConfig.countryCode === 'BE' ? (
            <BelgiumPostalProcuration />
          ) : (
            <Fragment>
              {postalSignatureLink && (
                <>
                  <LegalDocumentDownload
                    title={t('profile:documents.postalProcuration.fr.title')}
                    subtitle={t(
                      'profile:documents.postalProcuration.fr.subtitle'
                    )}
                    href={postalSignatureLink}
                    cta={t('common:signTheDocument')}
                  />
                  <Alert
                    message='Cette action est obligatoire pour profiter pleinement de vos services.'
                    type='warning'
                  />
                </>
              )}
              <LegalDocumentDownload
                title={t('profile:documents.postalProcuration.fr.title')}
                subtitle={t('profile:documents.postalProcuration.fr.subtitle')}
                href={postalProcurationFlow?.link}
                cta={t('common:download')}
              />
            </Fragment>
          ))}

        {domiciliation.address.kbis && (
          <LegalDocumentDownload
            title={t('profile:documents.addressKbis.title')}
            subtitle={t('profile:documents.addressKbis.subtitle')}
            cta={t('common:download')}
            href={domiciliation.address.kbis}
          />
        )}

        {domiciliation.documentHostFile && (
          <LegalDocumentDownload
            title={t('profile:documents.accountingAttestation.title')}
            subtitle={t('profile:documents.accountingAttestation.subtitle')}
            cta={t('common:download')}
            href={domiciliation.documentHostFile}
          />
        )}
      </List>
    </Wrapper>
  )
}

export default AttestationsList
