import { useContext } from 'react'
import { AbilityContext } from 'src/contexts/abilityContext'
import {
  INVOICES,
  NOTIFICATIONS,
  PAYMENT_INFORMATIONS,
  SECURITY,
  GENERAL_INFORMATIONS
} from './SettingsRoutes'
import NavigationLink from './sharedComponents/navigationLink'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'

interface IProps {
  onSetSidebarState: (open: boolean) => void
}

const SettingsNavigationLinks = (props: IProps) => {
  const ability = useContext(AbilityContext)
  const { domiciliation } = useContext(DomiciliationContext)

  return (
    <div className='gray-sidebar sidebar-settings setting-child'>
      <nav>
        {domiciliation && (
          <NavigationLink
            sectionName='Moyen de paiement'
            to={PAYMENT_INFORMATIONS}
            onClick={() => props.onSetSidebarState(false)}
            authorized={ability.can('customer_read', 'PaymentSource')}
          />
        )}

        <NavigationLink
          sectionName='Factures'
          to={INVOICES}
          onClick={() => props.onSetSidebarState(false)}
          authorized={ability.can('customer_read', 'Invoice')}
        />

        <NavigationLink
          sectionName='Confidentialité'
          to={SECURITY}
          onClick={() => props.onSetSidebarState(false)}
        />

        {domiciliation && (
          <NavigationLink
            sectionName='Communication'
            to={NOTIFICATIONS}
            onClick={() => props.onSetSidebarState(false)}
            authorized={
              domiciliation && ability.can('customer_read', 'Notification')
            }
          />
        )}

        <NavigationLink
          sectionName='Informations générales'
          to={GENERAL_INFORMATIONS}
          onClick={() => props.onSetSidebarState(false)}
        />
      </nav>
    </div>
  )
}

export default SettingsNavigationLinks
