import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Form from './Form'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const Notification = () => {
  const { t } = useTranslation()
  const { domiciliation } = React.useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  // const [upsertUserLocale] = useMutation(UPSERT_USER_LOCALE)
  // const { locale } = useContext(CurrentUserContext)
  // const [notificationLocale, setNotificationLocale] = useState(locale)

  // const onLanguageChange = (lng) => {
  //   upsertUserLocale({ variables: { locale: lng.toUpperCase() } })
  //   i18n.changeLanguage(lng)
  //   setNotificationLocale(lng)
  // }

  return (
    <div className='settings-block notifications-section'>
      <h2 className='subtitle-sidebar'>
        {t('notifications:title')}
        <div className='explain-container'>
          <p>{t('notifications:hint')}</p>
        </div>
      </h2>

      <div className='notification-element'>
        <div className='bloc-notification-large'>
          <p>{t('notifications:email.title')}</p>
          <p className='info-sub'>{t('notifications:email.sentTo')}</p>
          <p className='info-sub'>{contact.email}</p>
        </div>
      </div>

      {/* <div className='notification-element'>
        <div className='bloc-notification-large'>
          <p>{t('notifications:language')}</p>
        </div>
        <div className='bloc-notification-right'>
          <Select
            style={{ minWidth: '100px' }}
            value={notificationLocale}
            onChange={(value) => onLanguageChange(value)}
          >
            <Select.Option value='FR'>{t('common:languages.fr')}</Select.Option>
            <Select.Option value='EN'>{t('common:languages.en')}</Select.Option>
          </Select>
        </div>
      </div> */}

      {/* Not used for now, will be, when english mails will be implemented */}
      {/* <div className='flex items-center justify-between'>
        <div>Langue des emails</div>
        <Can I='customer_manage' a='Domiciliation'>
          <LanguageSelect />
        </Can>
      </div> */}

      <Form initialValues={domiciliation.notificationUnsubscription} />
    </div>
  )
}

export default Notification
