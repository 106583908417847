import { IFailedPayment } from '@/interfaces/failedPayment'
import { useTranslation } from 'react-i18next'
import { ContactContext } from 'src/contexts/contactContext'
import InformationCircleO from '@/images/information-circle-o.svg'
import TimeIcon from '@/images/time.svg'
import React, { useContext, useEffect, useState } from 'react'
import { AbilityContext } from '@/contexts/abilityContext'
import useActionCable from '@/hooks/useActionCable'
import useChannel from '@/hooks/useChannel'
import { Modal, notification } from 'antd'
import RegularizeFailedPaymentsModal from './RegularizeFailedPaymentsModal'

interface IProps {
  unpaidInvoices: IFailedPayment[]
  refresh: () => void
}

const RegularizeFailedPayments = (props: IProps) => {
  const { t } = useTranslation()
  const { contact } = useContext(ContactContext)
  const ability = useContext(AbilityContext)
  const canPay = ability.can('customer_manage', 'Invoice')

  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [regularizedDone, setRegularizedDone] = useState<boolean>(false)

  const [regularizeModalOpened, setRegularizeModalOpened] =
    useState<boolean>(false)

  const closeRegularizeModal = () => {
    setRegularizeModalOpened(false)
  }

  const successRegularizeModal = () => {
    setRegularizeModalOpened(false)
    setRegularizedDone(true)
    notification.success({
      message: t('invoices:regularize.form.success.message'),
      description: t('invoices:regularize.form.success.description'),
      duration: 15
    })
  }

  const handleCloseModal = () => {
    setModalOpened(false)
  }

  const totalDue = () => {
    return props.unpaidInvoices
      .reduce(
        (sum, unpaidInvoice: IFailedPayment) => sum + unpaidInvoice.total,
        0
      )
      .toFixed(2)
  }

  const host = new URL(
    `https://${process.env['REACT_ROOT_PATH_' + contact.countryConfig.countryCode]}` ||
      ''
  ).host

  const protocol =
    process.env.REACT_APP_ENVIRONMENT === 'development' ? 'ws' : 'wss'
  const { actionCable } = useActionCable(`${protocol}://${host}/cable`)

  const { subscribe, unsubscribe } = useChannel(actionCable)

  useEffect(() => {
    subscribe(
      { channel: 'RegularizeFailedPaymentsChannel' },
      {
        received: (data) => {
          if (data.event === 'payment_status_updated') {
            props.refresh()
          }
        }
      }
    )
    return () => {
      unsubscribe()
    }
  }, [])

  if (!canPay) {
    return <React.Fragment />
  } else {
    return (
      <>
        <Modal
          data-id='regularize-risks-modal'
          open={modalOpened}
          onCancel={handleCloseModal}
          footer={null}
          title={t('invoices:modal.title')}
        >
          <div className='font-weight-bold section-warning'>
            {t('invoices:modal.content.title')}
          </div>
          {[1, 2, 3].map((i) => (
            <div key={i}>
              <div className='font-weight-bold text-danger section-warning'>
                {t(`invoices:modal.content.block${i}Title`, {
                  interpolation: { escapeValue: false }
                })}
              </div>
              <div className=''>
                {t(`invoices:modal.content.block${i}Content`, {
                  interpolation: { escapeValue: false }
                })}
              </div>
            </div>
          ))}
        </Modal>

        <div className='regularize-header'>
          {!regularizedDone && (
            <>
              <div className='alert-message'>
                <div className='alert-header'>
                  <img src={InformationCircleO} />
                  <span>{t('invoices:regularize.alertHeader')}</span>
                </div>
                <span
                  className='alert-button'
                  onClick={() => setModalOpened(true)}
                >
                  {t('invoices:regularize.alertButton')}
                </span>
              </div>
              <div className='regularize-message'>
                <span>
                  <span className='regularize-sum-title'>
                    {t('invoices:regularize.sumTitle')}
                  </span>
                  <span className='regularize-sum-price'>{totalDue()}€</span>
                </span>
                <span
                  className='regularize-button'
                  onClick={() => setRegularizeModalOpened(true)}
                >
                  {t('invoices:regularize.sumButton')}
                </span>
                {regularizeModalOpened && (
                  <RegularizeFailedPaymentsModal
                    close={closeRegularizeModal}
                    success={successRegularizeModal}
                  />
                )}
              </div>
            </>
          )}

          <div className='time-message'>
            <img src={TimeIcon} />
            <span>{t('invoices:regularize.timeWarning')}</span>
          </div>
        </div>
      </>
    )
  }
}

export default RegularizeFailedPayments
